// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/countries/config.ts"
);
import.meta.hot.lastModified = "1715264889015.3633";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/settings/countries',
}
